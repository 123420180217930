import React from 'react'
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { oceanBlue } from '../../constants/theme';


const MaskNotice = styled(props => <Link {...props} />)`
  font-family: 'HKNova-Bold', sans-serif;
  color: ${oceanBlue};
  padding: 30px;
  font-size: 15px;
  margin-bottom: 0;
  text-align: justify;
  font-family: 'HKNova-Regular';
  line-height: 1.6;
  display: block;
  background-color: rgba(71,131,181,0.10);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MaskBulkExtra = () => { 
  
  return (
  <MaskNotice to={'/products/copper-compression-copper-infused-face-mask-bulk-boxes/'}>
    We've heard your requests! We're happy to announce new bulk pricing options for masks!
  </MaskNotice>
)};

export default MaskBulkExtra
