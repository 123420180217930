/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { skyBlue, oceanBlue } from '../../constants/theme'
import fbTrack from '../../utils/fbTrack'
import snapTrack from '../../utils/snapTrack'

import SEO from '../../components/seo'
import ProductForm from '../../components/ProductForm'
import ProductHeader from '../../components/ProductHeader'
import DrewVideo from '../../components/DrewVideo'
import DrewSticker from '../../components/drewSticker'
import SlickBlock from '../../components/SlickBlock'
import MaskExtra from './MaskExtra'
import AprilFoolsExtra from './AprilFoolsExtra'
import MaskBulkExtra from './MaskBulkExtra'
import GuaranteeBlock from './GuaranteeBlock'

import mandalaImg from '../../images/products/mandala.jpg'
import iconsImg from '../../images/products/benefits.jpg'
import drewSticker2 from '../../images/drew/sticker2.jpg';
import drewSticker3 from '../../images/drew/sticker3.jpg';

import {
  Img,
  Container
} from '../../utils/styles'

import {
  ProductBreadcrumbs,
  ProductDescription,
  ProductFormContain,
  ProductGrid,
  ImageGridStyle,
  ImageBlockStyle,
  GridLeft,
  GridRight
} from './styles'

import { ProductFeaturesAccordion } from './AccordionWrap'

import {
  ProductStarsWidget,
  ProductReviewWidget,
  useYotpo,
} from '../../components/Reviews';

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

function ProductJsonLd(product, productAdmin, parentVariant, yotpoData) {

  const priceValidUntilDate = addMonths(new Date(), 2).toISOString()

  const merchantDataString = productAdmin.variants.edges.map(variant => {

    const variantId = variant.node.id.replace('gid://shopify/ProductVariant/', '');
    const availabilityString = variant.node.availableForSale ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'

    // Only add review data if it exists
    let reviewData = '';
    if (yotpoData && yotpoData.score && yotpoData.totalReviews) {
      reviewData = `
        "@type": "AggregateRating",
        "ratingValue": "${yotpoData.score}",
        "reviewCount": "${yotpoData.totalReviews}"
      `;
    }

    return (
      `{
      "@context": "http://schema.org/",
      "@type": "Product",
      "sku": "${variant.node.sku}",
      "mpn": "${variant.node.sku}",
      "id": "shopify_US_${parentVariant}_${variantId}",
      "brand": {
        "@type": "Thing",
        "name": "Copper Compression"
      },
      "audience": {
        "@type": "PeopleAudience",
        "suggestedGender": "unisex"
      },
      "image": "${product.images[0].originalSrc}",
      "name": "${product.title}",
      "description": "${product.description}",
      "offers": {
        "@type": "Offer",
        "url": "https://coppercompression.com/products/${product.handle}?variant=${variantId}",
        "priceCurrency": "USD",
        "price": "${variant.node.presentmentPrices.edges[0].node.price.amount}",
        "priceValidUntil": "${priceValidUntilDate}",
        "itemCondition": "http://schema.org/NewCondition",
        "availability": "${availabilityString}"
      },
      "aggregateRating": {
        ${reviewData}
      }
    }`
    )
  })

  return merchantDataString;

}

const ImageGrid = ({ title, images, video, noSizingInstructions, productImageRatio }) => { 

  return(
    images.map((image, index) => {
      if (!noSizingInstructions && index >= images.length - 1) { return false; }

      return (
        <div
          className='product-image-contain'
          key={ image.id }
        >
          { (video && index === 0) && <video className='product-video' playsInline autoPlay muted loop src={ video } /> }
          { (!video || index !== 0) &&
            <Img
              alt={ title }
              fluid={ { ...image.localFile.childImageSharp.fluid, aspectRatio: productImageRatio } }
            />
          }
        </div>
      )
    })
  )
}


const ProductPage = ({ data }) => {

  const product = data.shopifyProduct
  const productAdmin = data.admin.productByHandle
  const parentVariantAdmin = productAdmin.id.replace('gid://shopify/Product/', '');
  const seoDescription = productAdmin.seo.description ? productAdmin.seo.description : product.description;
  const seoTitle = productAdmin.seo.title ? productAdmin.seo.title : product.title;

  const noSizingInstructions = product.tags.includes("No Sizing")
  const updatedImages = product.tags.includes("Updated Images")
  const hideDrew = product.tags.includes("dbexclude")

  const parentVariant = data.allFile.edges.length > 0 ? data.allFile.edges[0].node.childTestingJson.parentVariant : null
  const features = data.allFile.edges.length > 0 ? data.allFile.edges[0].node.childTestingJson.features : null
  const video = data.allFile.edges.length > 0 ? data.allFile.edges[0].node.childTestingJson.video : null;
  const drewVideo = data.allFile.edges.length > 0 && data.allFile.edges[0].node.childTestingJson.drewVideo != null ? data.allFile.edges[0].node.childTestingJson.drewVideo : 'https://cdn.shopify.com/s/files/1/0992/9284/files/brees_general_product.mp4?v=1617633788';
  const instructImage = !noSizingInstructions ? product.images[product.images.length - 1] : false;
  const productImageRatio = updatedImages ? 4 / 5 : 1 / 1;

  const showMaskExtra = product.handle === 'copper-infused-face-mask';
  const aprilFools = product.handle === 'full-body-compression-suit';

  // const typeSlug = `/type/${slugify(product.productType, {lower: true})}/`
  // const { search } = useLocation()
  // const showYotpo = qs.parse(search, { ignoreQueryPrefix: true }).yotpo ? true : false;

  const yotpoData = data.yotpoProductBottomline;
  const showYotpo = true;

  useEffect(useYotpo(showYotpo));

  useEffect(() => {
    fbTrack('ViewContent', {
      value: product.priceRange.maxVariantPrice.amount,
      currency: 'USD',
      content_ids: parentVariantAdmin,
      content_type: 'product_group',
    })
  });

  useEffect(() => {
    snapTrack('VIEW_CONTENT', {
      'item_category': parentVariantAdmin
    })
  });


  const imageGridSettings = {
    title: product.title,
    images: product.images,
    video,
    noSizingInstructions,
    productImageRatio,
  }

  return (
    <>
      <SEO
        title={ seoTitle }
        product={ product }
        parentVariant={ parentVariant }
        description={ seoDescription }>
        <script className="merchant-data" type="application/ld+json">
          { `[${ProductJsonLd(product, productAdmin, parentVariantAdmin, yotpoData)}]` }
        </script>
      </SEO>
      <Container>

        <ProductBreadcrumbs>
          <Link to="/collections/all-1">
            All:&nbsp;
          </Link>
          { product.productType }
        </ProductBreadcrumbs>

        <ProductGrid>
          <GridLeft className='small-grid'>
            <ProductHeader product={ product } />
            <SlickBlock>
              <ImageGrid { ...imageGridSettings } />
            </SlickBlock>
            <ImageGridStyle>
              <ImageGrid { ...imageGridSettings } />
            </ImageGridStyle>
          </GridLeft>

          <GridRight>
            <ProductFormContain>
              <ProductForm product={ product } parentVariant={ parentVariant }>
                <ProductStarsWidget handle={ product.handle } parentVariant={ parentVariant } />
              </ProductForm>
            </ProductFormContain>

            { showMaskExtra && <MaskBulkExtra /> }

            <ProductDescription
              dangerouslySetInnerHTML={ { __html: product.descriptionHtml } }
            />
            { features && <ProductFeaturesAccordion features={ features } /> }
            { instructImage &&
              <Img
                fluid={ { ...instructImage.localFile.childImageSharp.fluid } }
                alt={ instructImage.title }
              />
            }
          </GridRight>
        </ProductGrid>
      </Container>

      { showMaskExtra && <MaskExtra /> }
      { aprilFools && <AprilFoolsExtra /> }
      { !hideDrew && <DrewVideo vidUrl={drewVideo} /> }

      <ProductReviewWidget product={ product } parentVariant={ parentVariant } />

      { !hideDrew &&
        <>
          <DrewSticker showName reverse img={ drewSticker2 } bg={ skyBlue } caption="“After all of the years of wearing compression products, I find that Copper Compression is absolutely the best!”" />
          <DrewSticker showName img={ drewSticker3 } bg={ oceanBlue } caption="“It doesn’t matter if you're a pro athlete, weekend warrior, or weekday worker - Copper Compression is for everyone”" />
        </>
      }

      <Container>
        <ImageBlockStyle>
          <img
            src={ mandalaImg }
            alt='Manadala'
          />
          <img
            src={ iconsImg }
            alt='Manadala'
          />
        </ImageBlockStyle>
      </Container>

      <GuaranteeBlock />
    </>
  )
}

export const query = graphql`
  query($handle: String!, $parentVariant: String!) {
    allFile(filter: {name: {eq: $handle}, sourceInstanceName: {eq: "content"}}) {
      edges {
        node {
          id
          childTestingJson {
            title
            content
            video
            drewVideo
            parentVariant
            features {
              title
              content
            }
            sizing {
              title
              describe
              header
              inches
              cm
            }
          }
        }
      }
    }
    admin {
      productByHandle(handle: $handle) {
        id
        seo {
          description
          title
        }
        variants(first: 6) {
          edges {
            node {
              id
              availableForSale
              presentmentPrices(first: 1) {
                edges {
                  node {
                    price {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      tags
      vendor
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        compareAtPrice
        availableForSale
        shopifyId
        product {
          handle
        }
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        id
        originalSrc
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    yotpoProductBottomline(productIdentifier: { eq: $parentVariant }) {
      score
      totalReviews
    }
  }
`

export default ProductPage
