/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */

import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { oceanBlue, skyBlue } from '../../constants/theme';
import { breakpoints } from '../../utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Price } from '../Price';

const ProductNoticeStyle = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  z-index: 20000;
  display: flex;
  justify-content: center;
  text-align: right;
  pointer-events: none;

  @media (max-width: ${breakpoints.l}px){
    top: 0;
  }

  transition: opacity 320ms ease-out 20ms;
  opacity: ${props => props.shouldShow ? 1 : 0};

  .containment {
    max-width: 1340px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
    box-sizing: border-box;
    pointer-events: ${props => props.shouldShow ? 'auto' : 'none'};
    max-width: 420px;
    padding: 20px 30px;
    width: 100%;
    justify-content: flex-start;
    border-bottom: 2px solid ${skyBlue};
    box-shadow: -3px 3px 12px rgba(0,0,0,0.4);
  }

  .product-top-row {
    font-family: 'HKNova-Medium';
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    font-size: 13px;
    cursor: pointer;
    color: ${skyBlue};
    text-decoration: none;

    svg {
      margin-right: 5px;
    }

  }

  .product-display {

    display: flex;

    img {
      border-radius: 64px;
      width: 64px;
      height: 64px;
      background-color: white;
      padding: 6px;
      border: 2px solid ${skyBlue};
    }

    .product-info {
      font-family: 'HKNova-Medium';
      width: calc(100% - 100px);
      text-align: left;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      h2 { 
        font-size: 15px;
        margin: 0 0 12px 0;
        font-family: 'HKNova-Bold';
      }
      h3 { font-size: 13px; margin: 0; }
      h3 + h3 { margin-top: 5px; }
      h3.compare-price {color:red;}
      justify-content: center;
      h3 span {
        padding-left: .5rem;
        opacity: .6;
        text-decoration: line-through;
      }
    }
  }

  .product-buttons {

    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin: 32px 0 12px 0;
    justify-content: space-between;

    .btn {
      font-family: 'HKNova-Medium';
      width: 50%;
      padding: 14px 24px;
      border: 1px solid grey;
      background-color: white;
      font-size: 13px;
      border-radius: 24px;
      outline: 0;
      text-decoration: none;
      margin: 0;
      text-align: center;
      color: ${oceanBlue};

      &:hover {
        background-color: ${skyBlue};
        color: white;
      }
    }

    .btn + .btn {
      margin-left: 20px;
      background-color: ${oceanBlue};
      color: white;
      &:hover {
        background-color: ${skyBlue};
        color: white;
      }
    }

  }
`;



export const ProductNotice = ({ shouldShow, title, cartImage, filteredTitle, variant, tags, setShowCart, handleCheckout }) => <ProductNoticeStyle shouldShow={ shouldShow }>
  <div className="containment">
    <div className="content">

      <a href="javascript:void(0);" className="product-top-row" onClick={ () => setShowCart(false) }>
        <div>
          <FontAwesomeIcon icon={ faCheckCircle } />
            Added to Cart
          </div>
        <div className='close'>X</div>
      </a>

      <div className='product-display'>
        <img alt={ title } src={ cartImage.localFile.childImageSharp.fluid.src } />
        <div className='product-info'>
          <h2>{ filteredTitle }</h2>
          <Price variant={variant} tags={tags} TagWrapper='h3' TagInner='span' />
        </div>
      </div>

      <div className="product-buttons">
        <Link className='btn' to='/cart'>View Cart</Link>
        <a className='btn' href="javascript:void(0);" onClick={ handleCheckout }>Checkout</a>
      </div>
    </div>
  </div>
</ProductNoticeStyle>;
