import React from "react";
import { Link } from 'gatsby'

import { Container } from '../../utils/styles'
import { Guarantee, GuaranteeWrap } from './styles'

const GuaranteeBlock = () => (
  <Container>
      <GuaranteeWrap>
        <Guarantee>
          <div className="contain">
            <h3>Copper Compression</h3>
            <h1>30-Day Unconditional<br />Money-Back Guarantee</h1>

            <div className="grid">
              <div>
                <h4><a href="mailto:help@coppercompression.com">help@coppercompression.com</a></h4>
                <p>If you have any problems or questions about your return, please contact us.</p>
              </div>
              <div>
                <p className="right-side">We back all of our products with a money-back guarantee. If you are not completely satisfied with this product for any reason, we will gladly refund your purchase price, excluding shipping and handling charges, within 30 days of purchase.</p>
                <p className="right-side">If you choose to return or exchange your product you must email help@coppercompression.com We will provide shipping instructions at that time. We will need your order number, name, address, phone number, product to be returned. We will also need a copy of your invoice when you return the product.</p>
                <p className="right-side">
                  <Link to="/warranty">REGISTER YOUR WARRANTY</Link>
                </p>
              </div>
            </div>
          </div>
        </Guarantee>
      </GuaranteeWrap>
  </Container>
);

export default GuaranteeBlock
