import React from 'react';
import { Link } from 'gatsby';
import {
  // HeroImageBlockStyle, 
  StickerBlockStyle
} from '../../utils/styles';

const StickerBlock = (props) => (<StickerBlockStyle { ...props }>
  <div className="box">
    <div className='caption-area'>
      <h3>{ props.caption ? props.caption : 'Copper Compression' }</h3>
      <h1 className={ props.trademark ? 'has-trademark' : '' }>{ props.title }</h1>
      { props.children }
      { !props.hideCTA &&
        <Link className='cta' to={ props.destination ? props.destination : '/collections/all-1/' }>
          { props.ctaText ? props.ctaText : 'Shop' }
        </Link> }
    </div>
  </div>
  <div className="box box-image">
    <Link to={ props.destination ? props.destination : '/collections/all-1/' }>
      <img alt={ props.title } src={ props.boximage } />
    </Link>
  </div>
</StickerBlockStyle>);


export default StickerBlock;
