export default function klavCartTrack(product, variant, parentVariant) {

      const testItem = {
        Name: product.title,
        ProductID: parentVariant,
        Categories: product.productType,
        ImageURL: product.images[0].originalSrc,
        URL: `https://coppercompression.com/products/${product.handle}`,
        Brand: product.vendor,
        Price: variant.price,
        CompareAtPrice: variant.compareAtPrice
      };

  console.log(testItem)

  if (process.env.NODE_ENV === `production` && typeof window !== "undefined") {
    if (window._learnq != null) {
      const item = {
        Name: product.title,
        ProductID: parentVariant,
        Categories: product.productType,
        ImageURL: product.images[0].originalSrc,
        URL: `https://coppercompression.com/products/${product.handle}`,
        Brand: product.vendor,
        Price: variant.price,
        CompareAtPrice: variant.compareAtPrice
      };
      window._learnq.push(['track', 'Added to Cart', item]);
    }
  }
}
