import React from 'react'
import img from '../../images/aprilfools/aprilFools-strip.jpg';
import { Container } from '../../utils/styles'


const AprilFoolsExtra = () => <Container>
  <img alt='April Fools Extra' src={img} width="100%" />
</Container>

export default AprilFoolsExtra
