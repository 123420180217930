import React from 'react';
import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

import { oceanBlue } from '../../constants/theme';

import Icon1 from '../../images/icons/copper-icon-anti-microbial.png';

import Icon2 from '../../images/icons/copper-icon-washable.png';
import Icon3 from '../../images/icons/copper-icon-reusable.png';

export const IconsBlockStyle = styled.div`

  padding: 0;
  color: ${props => props.darkBG ? '#fff' : oceanBlue};
  background-color: ${props => props.bgColor ? props.bgColor : "rgba(71,131,181,.16)"};
  padding-bottom: ${props => props.tightBaseline ? '0' : '50px'};
  padding-top: ${props => props.tightBaseline ? '0' : '50px'};

  display: flex;

  flex-direction: row-reverse;

  @media(max-width: ${ breakpoints.l}px) {
    flex-direction: row;
    flex-wrap: wrap;
    max-height: unset;
    height: unset;
  }

  .icon-area {
    max-width: 400px;
    &-icon {

      padding: 12px 0;

      img {
        max-width: 64px;
        margin: auto;
        display: block;
      }
      h3 {
        letter-spacing: 0.350rem;
        font-family: 'gilroyExtraBold';
        text-transform: uppercase;
        font-weight: normal;
        font-size: 12px;
        margin-bottom: 21px;
        margin-left: 7px;
        text-align: center;
      }
      p {
        font-size: 13px;
        font-family: 'HKNova-Regular';
        max-width: 340px;
        margin-left: 0;
        text-align: center;
        line-height: 1.4;
      }
    }
  }

  .box {
    display: flex;
    width: 50%;
    align-items: center;
    padding: 20px;

    padding: ${props => props.tightBaseline ? '50px 20px' : '20px'};

    justify-content: center;

    @media(max-width: ${ breakpoints.l}px) {
      width: 100vw;
    }
  }


  .box.box-image {

    padding: 0;

    img {
      width: 100%;
      max-width: 800px;
      padding-left: 20px;
    }

    @media (min-width: 1680px){ 
      width: 800px;
      justify-content: ${props => props.mirror ? 'flex-end' : 'flex-start'};
      padding-left: 40px;
    }

    @media(max-width: ${ breakpoints.l}px) {
      padding-top: 50px;
      padding-left: 0;

      img {
        width: 90%;
        margin: auto;
      }
    }

  }

`;



const icons = [
  {
    img: Icon1,
    title: 'Anti-Microbial',
    caption:'Antimicrobial properties provide maximum benefits, extending the life of the product for a superior experience.',
  },
  {
    img: Icon2,
    title: 'Washable',
    caption:'Copper protection lasts through the lifespan of the product and never washes out',
  },
  {
    img: Icon3,
    title:'Reusable',
    caption:'Reduce waste and reuse your mask. Unlike disposables ours are washable and re-wearable'
  }
]

const IconsBlock = (props) => (<IconsBlockStyle { ...props }>
  <div className="box">
    <div className='icon-area'>
        {icons.map((icon, index) => 
          <div key={index} className='icon-area-icon'>
            <img alt={icon.title} src={icon.img} />
            <h3>{icon.title}</h3>
            <p>{icon.caption}</p>
          </div>
        )}
    </div>
  </div>
  <div className="box box-image">
    <img alt={ props.title } src={ props.boximage } />
  </div>
</IconsBlockStyle>);


export default IconsBlock;
