/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { Component } from "react";
import Slider from "react-slick";
import styled from '@emotion/styled'

import { Img } from '../../utils/styles'
import { skyBlue } from '../../constants/theme';

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const ProductDisplayStyle = styled.div`

  background-color: rgba(71,131,181,0.10);

  .dots {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 6px;
    height: 7px;
    line-height: 0;
    overflow: hidden;
    background-color: lightgrey;
    li {
      list-style: none;
      height: 7px;
      background-color: lightgrey;
      padding: 0;
      margin: 0;
      width: calc(100% / ${props => props.size});

      button:before {
        display: none;
      }
      button {
        width: 100%;
      }

      &.slick-active {
        background-color: ${skyBlue};
        border-radius: 6px;
      }

    }
  }
`;

export const ProductNavStyle = styled.div`
  .product-nav-slider {

  }



  .slick-current {
    background-color: white;
  }


  .product-image-contain {
    box-sizing: border-box;
    padding: 5px;
    background-color: rgba(71,131,181,0.10);
  }
`;


export const SlickGrid = styled.div`
  display: none;
  @media(max-width: ${ breakpoints.l}px) {
    display: block;
  }
`

export default class SlickBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }


  render() {

    const {
      images,
      video,
      title,
      productImageRatio,
      noSizingInstructions
    } = this.props.children.props
  
    return (
      <SlickGrid>
        <ProductDisplayStyle size={noSizingInstructions ? images.length - 2 : images.length - 3}>

        <Slider className="product-slider"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          arrows={false}
          swipeToSlide={true}
          dots={true}
          appendDots={dots => { 
            
            return (
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: "0",
                padding: "40px",
                boxSizing: 'border-box',
                bottom: 0,
                overflow: 'hidden',

              }}
            >
              <ul className='dots' style={{ margin: "0px" }}> {dots} </ul>
            </div>
          )}}
        >
          {
            images.map((x, index) => {

              if (!noSizingInstructions && index >= images.length - 1) { return false; }

              return (
                <div
                  className='product-image-contain'
                  key={ x.id }
                >
                  {(video && index === 0) && <video className='product-video' playsInline autoPlay muted loop src={ video } />}

                  {(!video || index !== 0) &&
                    <Img
                      alt={ title }
                      fluid={{ ...x.localFile.childImageSharp.fluid, aspectRatio: productImageRatio }}
                    />
                  }
                </div>
              )
            })
          }
        </Slider>
        </ProductDisplayStyle>
        <ProductNavStyle>
        <Slider
          className="product-slider product-nav-slider"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={3}
          centerMode={true}
          infinite={true}
          arrows={false}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {
            images.map((x, index) => {

              if (!noSizingInstructions && index >= images.length - 1) { return false; }

              return (
                <div
                  className='product-image-contain'
                  key={ x.id }
                >
                <Img
                  alt={ title }
                  fluid={{ ...x.localFile.childImageSharp.fluid, aspectRatio: productImageRatio }}
                />
                </div>
              )
            })
          }
        </Slider>
        </ProductNavStyle>
      </SlickGrid>
    );
  }
}
