import styled from '@emotion/styled'
import { copper, skyBlue, skyBlue10, oceanBlue } from '../../constants/theme';

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}


export const MobileImg = styled.img`
  display: block;
  @media(max-width: ${ breakpoints.l}px) {
    display: none;
  }
`

export const DesktopImg = styled.img`
  display: none;
  @media(max-width: ${ breakpoints.l}px) {
    display: block;
  }
`


export const ProductGrid = styled.div`
  display: flex;
  position: relative;
  padding: 0 0 20px 20px;

  @media(max-width: ${ breakpoints.s}px) {
    padding: 0;
  }

  @media(max-width: ${ breakpoints.l}px) {
    display: block;
  }
`

export const ImageBlockStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  margin: auto;
  box-sizing: border-box;

  img {
    width: calc(50% - 40px);
    padding: 20px;
  }

  @media (max-width: ${breakpoints.l}px){
    display: block;
    padding: 0;

    img {
      width: 100%;
      padding: 0;
      padding-top: 20px;
    }
  }
`;

export const ImageGridStyle = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.l}px){
    display: none;
  }

  .product-image-contain {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

export const GridLeft = styled.div`
  width: 60%;

  @media (max-width: ${breakpoints.l}px){
    width: 100%;
  }

  .product-video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 0;
    display: inline-block;
    line-height: 0;
    background-color: white;
  }

  .product-image-contain {
    /* transition: width 400ms, position 300ms; */
    background-color: rgb(100,100,100);
    /* width: calc(50% - 20px); */
    display: block;
    /* margin: 0 20px 20px 0; */
    line-height: 0;

    picture {
      opacity: 0.95;
    }

    /* @media (max-width: ${breakpoints.m}px){
      margin: 0 5px 5px 5px;
      width: calc(50% - 10px);
    } */
  }

  &.big-grid {
    .product-image-contain {
      transition: width 400ms;
      width: calc(100% - 20px);
    } 
  }
`

export const GridRight = styled.div`
  width: 40%;
  @media (max-width: ${breakpoints.l}px){
      width: 100%;
  }
`

export const ProductBreadcrumbs = styled.h1`
  font-size: 12px;
  word-wrap: break-word;
  font-weight: normal;
  letter-spacing: 0.35em;
  font-family: 'gilroyExtrabold','HKNova-Medium', sans-serif;
  padding: 25px 20px;
  color: ${oceanBlue};
  line-height: 1.4;
  text-transform: uppercase;
  color: ${skyBlue};
  display: block;

  @media (max-width: ${breakpoints.l}px){
      display: none;
  }

  a {
    text-decoration: none;
    color: ${skyBlue};
    &:hover {
      color: ${oceanBlue};
    }
  }
`

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductDetailsHighlight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  font-family: 'HKNova-Regular', sans-serif;

  h1 {
    font-family: 'HKNova-Medium', sans-serif;
    font-size: 26px;
    margin-top: 60px;
    color: ${skyBlue};
  }
  h2 {
    font-size: 18px;
    margin-bottom: 40px;
    padding: 10px;
    padding-left: 0;
    color: ${oceanBlue};
  }
`;

export const ProductDescription = styled.div`

  font-family: 'HKNova-Regular', sans-serif;
  color: ${oceanBlue};
  padding: 30px;
  font-size: 15px;
  text-align: justify;
  font-family: 'HKNova-Regular';
  line-height: 1.6;

  img {
    max-width: 100%;
  }

  b {
    font-family: 'HKNova-Medium', sans-serif;
    color: ${skyBlue};
  }

  h2 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    margin: 25px auto;
  }


  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: circle;
    margin-bottom: 14px;
  }
`

export const GuaranteeWrap = styled.div`
  max-width: 1320px;
  margin: auto;
  padding: 20px 20px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.l}px){
    padding: 20px 0;
  }

`;

export const Guarantee = styled.div`
  background-color: ${skyBlue10};
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.s}px){
    padding: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.m}px){
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
  }

  a {
    color: ${skyBlue};
    text-decoration: none;
    margin-top: 20px;
    display: block;
    &:hover {
      color: ${oceanBlue};
    }
  }

  .contain {
    max-width: 1320px;
    margin: auto;
  }

  p {
    font-size: 12px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    max-width: 320px;
    line-height: 1.4;
  }

  p.right-side {
    margin-left: auto;
    margin-right: 20px;

    @media (max-width: ${breakpoints.s}px){
      margin: auto;
      max-width: 100%;
    }


  }


  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;
  }

  h4 {
    font-size: 12px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.2rem;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0 0 5px 0;

    a {
      @media (max-width: ${breakpoints.l}px){
        font-size: 10px;
      }
    }
  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
    border-radius: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
      @media (max-width: ${breakpoints.l}px){
        font-size: 32px;
      }
  }
`;

export const ProductFormContain = styled.div`


  .desktop-only {
    @media (max-width: ${breakpoints.l}px){
      display: none;
    }
  }

  font-family: 'gilroyExtrabold', sans-serif;
  background-color: ${skyBlue10};
  padding: 30px;
  color: ${skyBlue};
  margin-bottom: 20px;
  display: block;
  border-bottom: 3px solid ${copper};


  h4 {
    font-family: 'gilroyExtrabold', sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.35em;
    color: ${oceanBlue};
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: ${oceanBlue};

    @media (max-width: ${breakpoints.l}px){
      font-size: 24px;
    }


  }

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 1.7;
    display: inline-block;
    font-weight: normal;
    letter-spacing: 0.1em;
  }

  .compare-price {padding-left: 20px; opacity:0.6; text-decoration: line-through;}

  label {
    display: block;
    font-weight: normal;
    padding-bottom: 12px;
    font-size: 13px;
  }

  select {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    height: 48px;
    border-radius: 0;
  }


`;

