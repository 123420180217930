/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react'

import { useVideojs } from 'react-videojs-hook';
import 'video.js/dist/video-js.css';

const DrewVideo = ({vidUrl}) => {

  const onPlay = (currentTime) => {
//   console.log("Video played at: ", currentTime);
  };

  const onPause = (currentTime) => {
//   console.log("Video paused at: ", currentTime);
  };

  const onEnd = (currentTime) => {
//    console.log(`Video ended at ${currentTime}`);
  };

  const onTimeUpdate = (currentTime) => {
//    console.log(`Video current time is ${currentTime}`)
  };

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src: vidUrl,
    controls: true,
    autoplay: false,
    responsive: true,
    aspectRatio: "16:9",
    bigPlayButtonCentered: true,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
  });

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return (
    <div style={ { maxWidth: '1280px', margin: 'auto', width: '100%' } } >
      <div data-vjs-player>
        <video playsInline style={ { margin: 'auto', width: '100%' } } ref={vjsRef} id={vjsId} className={vjsClassName}></video>
      </div>
    </div>
  )

}


export default DrewVideo;
