import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { oceanBlue, oceanBlue30 } from '../../constants/theme';
import { breakpoints } from '../../utils/styles'

const ProductHeader = ({ product }) => {

  const ProductHeaderStyle = styled.div`
    font-size: 13px;
    padding: 10px;
    background-color: white;
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.1em;
    color: ${oceanBlue};
    display: none;
    flex-direction: column;

    @media (max-width: ${breakpoints.l}px){
      display: flex;
    }

    a {
      color: ${oceanBlue};
      text-decoration: none;
      font-size: 10px;
      letter-spacing: 0.1em;
    }
    h2 {
      margin: 0;

      span {
        padding-left: .5rem;
        text-decoration: line-through;
        color: ${oceanBlue30};
      }
    }
    .type-row {
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
      align-content: flex-start;
      color: ${oceanBlue};
      text-decoration: none;
      font-size: 10px;
      letter-spacing: 0.1em;
    }
    .header-row {
      margin-top: 10px;
      display: flex;
    }
  `;

  const {
    title,
    productType,
  } = product

  const filteredTitle = title.replace('Copper Compression','');
//  const typeSlug = `/type/${slugify(productType, {lower: true})}/`

  return (
    <ProductHeaderStyle>
      <div className="type-row">
        <div>
          <Link to="/collections/all-1">
              All:&nbsp;
          </Link>
          { productType }
        </div>
      </div>
      <div className="header-row">
        <h2>{filteredTitle}</h2>
      </div>
    </ProductHeaderStyle>
  )
}

ProductHeader.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductHeader
