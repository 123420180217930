import React, { useState } from 'react';
import styled from '@emotion/styled';
import { oceanBlue } from '../../constants/theme';

const AccordionWrap = styled.div`
  margin: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  color: ${oceanBlue};
`;


const AccordionWrapTitle = styled.div`
  font-size: 20px;
  font-family: 'HKNova-Medium';
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-bottom: 18px;
  border-top: 1px solid rgba(0,0,0,0.3);
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 2px;
    background-color: ${oceanBlue};
    position: absolute;
    top: 27px;
    right: 18px;
    transform: rotate(45deg);
    color: rgba(0,0,0,0.9);
    font-size: 12px;
    transition: transform 250ms cubic-bezier(0.86,0,0.07,1);
  }

  &::after {
    content: '';
    width: 9px;
    height: 2px;
    color: rgba(0,0,0,0.9);
    background-color: ${oceanBlue};
    top: 27px;
    right: 12px;
    position: absolute;
    display: block;
    transform: rotate(-45deg);
    transition: transform 250ms cubic-bezier(0.86,0,0.07,1);
  }

  &.title-closed::before {
    color: rgba(0,0,0,0.5);
    transform: rotate(-45deg);
    transition: transform 250ms cubic-bezier(0.86,0,0.07,1);
  }

  &.title-closed::after {
    color: rgba(0,0,0,0.5);
    transform: rotate(45deg);
    transition: transform 250ms cubic-bezier(0.86,0,0.07,1);
  }
`;
const AccordionWrapItems = styled.ul`
  font-size: 14px;
  list-style: dot;
  list-style-position: outside;
  font-family: 'HKNova-Regular';
  margin: 0;
  padding: 0;
  padding-left: 35px;
  margin-bottom: 0;
  overflow: hidden;

  &.panel-open {
    max-height: 1600px;
    transition: max-height 250ms cubic-bezier(0.86, 0, 0.07, 1);
  }

  &.panel-closed {
    max-height: 1px;
    transition: max-height 250ms cubic-bezier(0.86, 0, 0.07, 1);
  }

  li {
    margin-bottom: 6px;
    padding-left: 4px;

    &:last-of-type {
      padding-bottom: 20px;
    }
  }
`;

export const ProductFeaturesAccordion = ({ features }) => {

  const [openPanel, setOpenPanel] = useState(0);

  const clickedPanelToggle = (key) => {
    if (openPanel === key) {
      setOpenPanel(null);
    }
    else {
      setOpenPanel(key);
    }
  };

  return (<AccordionWrap>
    { features.map((feature, key) => <div className='feature-sections' key={ key }>
      <AccordionWrapTitle onClick={ () => clickedPanelToggle(key) } className={ key === openPanel ? 'title-open' : 'title-closed' }>
        { feature.title }
      </AccordionWrapTitle>
      <AccordionWrapItems className={ key === openPanel ? 'panel-open' : 'panel-closed' }>
        { feature.content.map((item, index) => <li key={index} className='feature-section-items-item'>{ item }</li>) }
      </AccordionWrapItems>
    </div>) }
  </AccordionWrap>);
};
