export default function klavTrackView(product, variant, parentVariant) {
  if (process.env.NODE_ENV === `production` && typeof window !== "undefined") {
    if (window._learnq != null) {
      const item = {
        Name: product.title,
        ProductID: parentVariant,
        Categories: product.productType,
        ImageURL: product.images[0].originalSrc,
        URL: `https://coppercompression.com/products/${product.handle}`,
        Brand: product.vendor,
        Price: variant.price,
        CompareAtPrice: variant.compareAtPrice
      };
      window._learnq.push(['track', 'Viewed Product', item]);

      window._learnq.push(['trackViewedItem', {
        Title: product.title,
        ItemId: parentVariant,
        Categories: product.productType,
        ImageUrl: product.images[0].originalSrc,
        URL: `https://coppercompression.com/products/${product.handle}`,
        Metadata: {
          Brand: product.vendor,
          Price: variant.price,
          CompareAtPrice: variant.compareAtPrice
        }
      }])
    }
  }
}
