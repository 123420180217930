import React, { useState, useContext, useEffect } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import StoreContext from '../../context/StoreContext'
import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles';
import { lighten, darken } from 'polished'
import fbTrack from '../../utils/fbTrack'
import snapTrack from '../../utils/snapTrack'
import { oceanBlue, copper } from '../../constants/theme';
import FormContain from '../FooterForm/formContain.js';
import { ProductNotice } from './ProductNoticeStyle'
import { Price } from '../Price';
import klavTrack from '../../utils/klavTrack'
import klavCartTrack from '../../utils/klavCartTrack'

const OptionButton = styled.button`
  margin: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 20px;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
  color: black;
  background-color: white;
  transition: background-color 200ms;
  font-size: 16px;
  cursor: pointer;


  &.product-option-active {
    color: white;
    cursor: default;
    background-color: ${lighten(0.05, copper)};
    &:hover {
      background-color: ${lighten(0.05, copper)};
      color: white;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: default;
  }

  &:hover {
    transition: background-color 200ms;
    background-color: ${copper};
    color: ${oceanBlue};
  }

  &::-moz-focus-inner {
    border: none;
  }

  &:disabled {
    background-color: transparent;
    color: grey;
    cursor: cancel;
    &:hover {
      background-color: transparent;
      color: grey;
    }
  }
`;

const OptionContain = styled.div`
  display: flex;

  margin-bottom: 10px;

  .button-contain {
    margin-top: 10px;
  }

  .option-row {
    margin-right: 20px;
    &.option-row-hidden {
      display: none;
    }
  }

  h5 {
    margin: 0;
  }
`;

const ReviewContain = styled.div`
  margin-bottom: 40px;

  a {
    margin-left: 5px;
  }
`

const QuantityChangeButton = styled.button`
  border: 1px solid transparent;
  background-color: white;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 20px 0 0 20px;
  transition: background-color 200ms;
  width: 140px;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: default;
  }

  &::-moz-focus-inner {
    border: none;
  }

  &.increase-change {
    border-radius: 0 20px 20px 0;
  }

  &:hover {
    transition: background-color 200ms;
    background-color: ${lighten(0.05, copper)};
    cursor: pointer;
    color: ${oceanBlue};
  }

  &:active {
    color: white;
    background-color: ${lighten(0.05, copper)};
  }
`;

const QuantityRow = styled.div`
  display: flex;
  background-color: white;
  border-radius: 20px;
`;

const QuantityInput = styled.input`
  width: calc(100% - 144px);

  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    outline: none;
  }

  border: 1px solid transparent;
  border-radius: 0;
  font-size: 16px;
  padding: 9px 20px;
  text-align: center;
`;

const CheckoutArea = styled.div`
  margin-top: 40px;
`;

const AddCartButton = styled.button`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: ${oceanBlue};
  font-size: 20px;
  padding: 14px 20px;
  text-align: center;
  border-radius: 20px;
  border: 2px solid ${copper};
  color: white;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 200ms;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${darken(0.10, copper)};
    transition: background-color 200ms;
  }

  &.adding {
    background-color: ${copper};
    color: white;
  }
`;

const ButtonStyle = styled(Link)`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  background-color: white;
  font-size: 20px;
  padding: 8px 20px;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
  color: black;
  text-decoration: none;
  transition: background-color 200ms;

  &:focus {
    outline: none;
  }

  &.adding {
    background-color: ${lighten(0.05, copper)};
    color: white;
  }

  &:hover {
    background-color: ${lighten(0.05, copper)};
    transition: background-color 200ms;
  }
`;

const MobilePriceAction = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: none;

  @media (max-width: ${breakpoints.l}px){
    display: block;
  }


  h3 {
    margin-top: 20px;
    padding-right: 3px;
    font-size: 18px;
    color: black;

    .compare-price {
      padding-left: 5px;
      opacity: 0.4;
    }
  }

`


const InnerPriceTag = ({ children }) => {
  return (
    <span className="compare-price">{children}</span>
  )
}

const ProductForm = ({ parentVariant, product, children }) => {

  const [shouldShowCartNotice, setShouldShowCartNotice] = useState(false);

  const {
    options,
    variants,
    title,
    tags,
    variants: [initialVariant]
  } = product

  const cartImage = product.images[0];

  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)

  const {
    client,
    adding,
    checkout,
    addVariantToCart,
  } = useContext(StoreContext)

  const filteredTitle = title.replace('Copper Compression','');
  const hasProducts = checkout.lineItems.length > 0;

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) ||
    variant

  const [available, setAvailable] = useState(productVariant.availableForSale)

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkAvailability = productId => {
    client.product.fetch(productId).then(() => {
      // this checks the currently selected variant for availability

      const result = variants.filter(
        variant => variant.shopifyId === productVariant.shopifyId
      )
      setAvailable(result[0].availableForSale)
    })
  }


  useEffect(() => {
    klavTrack(product, variant, parentVariant)
  })

  const handleCheckout = () => {

    let checkoutUrl = checkout.webUrl
    let gaLinkParam = '';

    if (process.env.NODE_ENV === `production` && typeof ga === `function`) {
      try {
        /*global ga*/
        gaLinkParam = '&' + ga.getAll()[0].get('linkerParam');
        checkoutUrl += gaLinkParam;
      } catch (error) {
        console.log('Google Analytics Not Present');
      }
    }
    window.open(checkoutUrl)
  }

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  }

  const handleOptionChange = (optionIndex, value) => {
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) => isEqual(currentOptions, selectedOptions))

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity).then(() => {
      showCartNotice(productVariant);
      snapTrack('ADD_CART',{'item_category': parentVariant});
      klavCartTrack(product, variant, parentVariant);
      fbTrack('AddToCart', {
        value: productVariant.price,
        currency: 'USD',
        content_ids: parentVariant,
        content_type: 'product_group',
        contents: [
          {
            id: parentVariant,
            quantity: quantity
          }
        ],
      })
    })
  }

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [{
        name: name,
        value: value
      }]
    })
    if (match === undefined)
      return true
    if (match.availableForSale === true)
      return false
    return true
  }


  // const ExtraNotice = (props) => {

  //   const NoticeContain = styled.p`
  //     margin-bottom: 10px;
  //     margin: 0;
  //     background-color: white;
  //     padding: 20px;
  //     color: ${oceanBlue};
  //     font-family: "HKNova-Regular";
  //     font-size: 14px;

  //     b {
  //       font-family: "HKNova-Bold";
  //     }

  //   `;

  //   return <NoticeContain>{props.children}</NoticeContain>

  // }

  const OutOfStock = ({isMask, isJoke}) => {

    const message = "Sign up to recieve a message when we are back in stock"
    const source  = `Out of Stock - Notification Request: ${filteredTitle}`


    let title = isMask ? '' : message

    if (isJoke) {
      title = 'Join the preorder list - April 1st Only'
    }

    return (
      <div>
        {isJoke && <h3>Coming Soon!</h3>}
        {!isMask && !isJoke && <h3>Out of Stock</h3>}
        <FormContain productPage={true} isMask={isMask} hideName title={title} submit="Get Notified" source={source}/>
      </div>
    )
  }

  const showCartNotice = () => {
    setShouldShowCartNotice(true);
    setTimeout(() => {
      setShouldShowCartNotice(false);
    }, 4000);
  }

  options.map(({ name, values }, index) => {
    const firstVariantSelected = values[0] === variant.selectedOptions[index].value;
    const firstVariantAvailable = !checkDisabled(name, values[0]);

    if (firstVariantSelected && !firstVariantAvailable) {
      // if first variant is available skip all this stuff
      if (!checkDisabled(name, values[1])) {
        // the second variant is available
        handleOptionChange(index, values[1]);
        setAvailable(true);
      } else if (!checkDisabled(name, values[2])) {
        // the third variant is available
        handleOptionChange(index, values[2]);
        setAvailable(true);
      } else if (!checkDisabled(name, values[3])) {
        // the fourth variant is available
        handleOptionChange(index, values[3]);
        setAvailable(true);
      } else if (!checkDisabled(name, values[4])) {
        // the fifith variant is available
        handleOptionChange(index, values[4]);
        setAvailable(true);
      }
    }

    return true;
  })

  //  const updatedImages = product.tags.includes("Updated Images");
  //  const productImageRatio = updatedImages ? 4/5 : 1/1;

  const isJoke = filteredTitle === "Full Body Compression Suit"
  let isMask = filteredTitle === " Copper Infused Face Mask- 2 Pack"
  if(!isMask) { isMask = filteredTitle === " Copper Infused Face Mask (Pack of 2)"; }

  return (
    <div className="product-form">

      <ProductNotice shouldShow={shouldShowCartNotice} title={title} cartImage={cartImage} filteredTitle={filteredTitle} setShowCart={setShouldShowCartNotice} handleCheckout={handleCheckout} variant={variant} tags={tags} />

      <MobilePriceAction>
        <Price variant={variant} tags={tags} TagWrapper='h3' TagInner={InnerPriceTag} />
      </MobilePriceAction>

      <h4 className="desktop-only">Copper Compression</h4>
      <h2 className="desktop-only">{filteredTitle}</h2>
      <div className="desktop-only">
        <Price variant={variant} tags={tags} TagWrapper='h3' TagInner={InnerPriceTag} />    
      </div>

      <ReviewContain> {children} </ReviewContain>

      {!available && !isMask && <OutOfStock isJoke={isJoke} isMask={isMask} /> }

      {available &&

        <div className="option-row-contain">

          {isMask && <h5>Back In Stock Now!</h5>}

          {options.map(({ id, name, values }, index) => {

            const defaultOptions = name === 'Title';
            const optionRowClassName = defaultOptions ? 'option-row option-row-hidden' : 'option-row';

            return (
              <OptionContain key={id}>
                <div className={optionRowClassName}>
                <h5>{name}</h5>
                <div className="button-contain">
                  {values.map(value => (
                    <OptionButton
                      className={value === variant.selectedOptions[index].value ? 'product-option-active' : 'product-option'}
                      disabled={checkDisabled(name, value)}
                      type="button"
                      name={name}
                      id={`${name}-${value}`}
                      value={value}
                      key={`${name}-${value}`}
                      onClick={() => handleOptionChange(index, value)}
                    >
                      {value}
                    </OptionButton>
                  ))}
                </div>
                </div>
              </OptionContain>
            )
          })}
          <div className="option-row">
            <label htmlFor="quantity">Quantity </label>

            <QuantityRow>
              <QuantityChangeButton
                type="button"
                onClick={() => decreaseQuantity()}
              >
                -
              </QuantityChangeButton>
                <QuantityInput
                  readOnly={true}
                  type="text"
                  id="quantity"
                  name="quantity"
                  onChange={handleQuantityChange}
                  value={quantity}
                />
              <QuantityChangeButton
                type="button"
                className="increase-change"
                onClick={() => increaseQuantity()}
              >
                +
              </QuantityChangeButton>
            </QuantityRow>
          </div>
          <CheckoutArea>
              <AddCartButton disabled={!available || adding} className={adding ? 'add-to-cart adding' : 'add-to-cart'} onClick={handleAddToCart}>
                {!adding && "Add to Cart"}
                {adding && "Adding Item"}
              </AddCartButton>

              {hasProducts && <ButtonStyle to='/cart'>Checkout</ButtonStyle>}
          </CheckoutArea>
        </div>
      }

      {/* {isMask && <><br /><OutOfStock isMask={isMask} /></>} */}

    </div>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
