/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, useEffect, useContext } from 'react'
import { useForm } from '@formcarry/react'
import sha1 from 'sha1';
import ireTrack from '../../utils/ireTrack'
import StoreContext from '../../context/StoreContext'
import styled from '@emotion/styled'


const FormText = styled.div`
  text-transform: uppercase;
`;


const FormContain = ({productPage, title, title2, submit:submitText, source, hideName, integratedLayout, isMask, isFooter}) => {
  
  const {checkout} = useContext(StoreContext)
  const [submitReady, setSubmitReady] = useState(false);
  const [email, setEmail] = useState(false);

  const ifEmailIsPresent = (e) => {
    setSubmitReady(true);
  }

  const {state, submit} = useForm({
    id: 'dnGUtvGpjeU0'
  });

  const handleInput = event => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (state.submitted) { 
      ireTrack('trackConversion', 24948,
        {
          orderId: checkout.id,
          customerId: "",
          customerEmail: sha1(email)
        },
        {
          verifySiteDefinitionMatch: true
        }
      )
    }
  }, [email, state.submitted, checkout])

  return (
    <div className={productPage ? 'footer-form-contain product-page-footer-form': 'footer-form-contain'}>

      <form onSubmit={submit} id="quick-contact">
        <input type="hidden" name="source" value={source}/>
        <input type="hidden" name="form-name" value="quick-subscribe" />

        <div className="form-contain">

          { !integratedLayout && 
            <div className="form-header">

              { !isMask && !isFooter && <p style={{textTransform:'none',fontWeight:'normal', fontSize: '16px', color: '#222222' }}>
                {title}
                <br />
                {title2}
              </p>
              }

              { isFooter &&
                <h3>
                  {title}
                  <br />
                  {title2}
                </h3>
              }
            </div>
          }

          {state.submitted && <FormText>Thank you! You are now subscribed</FormText> }

          {!state.submitted &&
            <>
              { !hideName && <label htmlFor="contactFormName">Name</label> }
              { !hideName && <input type="text" id="contactFormName" name="name" /> }
              <label htmlFor="contactFormEmail">Email</label>
              <input onChange={handleInput} onInput={() => ifEmailIsPresent(true)} type="email" name="email" autoCorrect="off" id="contactFormEmail" autoCapitalize="off" />
              <input type="submit" disabled={!submitReady} value={submitText} className="btn btn-submit" />
            </>
          }

          { integratedLayout && 
            <div className="form-header">
              <h3>
                {title}
                <br />
                {title2}
              </h3>
            </div>
          }

        </div>
      </form>
    </div>
  )
}

export default FormContain
