import React from 'react'


import hospitalAhs from '../../images/hospital/hospital-ahs.png'
import hospitalDanbury from '../../images/hospital/hospital-danbury.png'
import hospitalFlatbush from '../../images/hospital/hospital-flatbush.png'
import hospitalLenox from '../../images/hospital/hospital-lenox.png'
import hospitalNyu from '../../images/hospital/hospital-nyu.png'
import hospitalRwj from '../../images/hospital/hospital-rwj.png'

import imgMaskDetails from '../../images/mask/mask-filter.jpg'
import imgMaskFace from '../../images/mask/mask-main.jpg';

import IconsBlock from '../../components/IconsBlock';
import StickerBlock from '../../components/StickerBlock';

import { Container } from '../../utils/styles'


const MaskExtra = () => { 
  
  const logoBoxStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };
  
  return (
  <Container>
    <IconsBlock hideCTA destination='/collections/health' mirror bgColor='white' caption='Copper-Infused Face Mask' boximage={ imgMaskDetails } title='The antibacterial effectiveness of copper never washes off!'>
    </IconsBlock>
    <StickerBlock hyperTitle hideCTA destination='/collections/health' bgColor='#efeeec' caption='Helping Doctors & Nurses' boximage={ imgMaskFace } title='18,000 Masks Donated'>
      <p>Copper Compression values the importance of this timing and given the shortage of vital PPE we have donated our entire on hand supply of new antibacterial Copper Infused Face Masks to the New York and New Jersey area Hospitals. We cannot emphasize enough our appreciation for the life-saving work that our healthcare professionals are doing at this critical time and proud to support them with washable, reusable and sustainable masks. #StrengthToHeal
              <br /><br />
              Our donations were delivered to the following health systems
              <div className="logo-grid" style={ {
          padding: '20px',
          paddingRight: '30px',
          marginTop: '30px'
        } }>
          <div style={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 50%)',
            gridRowGap: '40px'
          } }>
            <a className="logo" style={ logoBoxStyle } href="https://nyulangone.org/" rel="noopener noreferrer" target="_blank">
              <img alt='NYU Langone' width="70%" src={ hospitalNyu } />
            </a>
            <a className="logo" style={ logoBoxStyle } href="https://lenoxhill.northwell.edu/" rel="noopener noreferrer" target="_blank">
              <img alt='Northwell Lenox Hill' width="80%" src={ hospitalLenox } />
            </a>
            <a className="logo" style={ logoBoxStyle } href="https://www.danburyhospital.org/" rel="noopener noreferrer" target="_blank">
              <img alt='Danbury Hospital' width="70%" src={ hospitalDanbury } />
            </a>
            <a className="logo" style={ logoBoxStyle } href="https://www.atlantichealth.org/" rel="noopener noreferrer" target="_blank">
              <img alt='Atlantic Health' width="90%" src={ hospitalAhs } />
            </a>
            <a className="logo" style={ logoBoxStyle } href="http://www.flatbushhatzoloh.org/" rel="noopener noreferrer" target="_blank">
              <img alt='Flatbush Hatzoloh' width="45%" src={ hospitalFlatbush } />
            </a>
            <a className="logo" style={ logoBoxStyle } href="https://www.rwjbh.org/" rel="noopener noreferrer" target="_blank">
              <img alt='RW JBH' width="70%" src={ hospitalRwj } />
            </a>
          </div>
        </div>

      </p>
    </StickerBlock>
  </Container>
)};

export default MaskExtra
